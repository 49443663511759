import { Link } from "gatsby";
import React from "react";

import { GridWrapper, GridItem, Slot } from "./styles";

export const GeneralGrid = ({ items, isAlternative = false }) => {
  // if (isAlternative) {
  //     return (
  //         <GridWrapperAlt>
  //             {items?.map((item) => {
  //                 return (
  //                     <Link to={item.link}>
  //                         <GridItemAlt
  //                             style={{backgroundImage: `url(${item.background})`}}
  //                             dangerouslySetInnerHTML={{ __html: item.title }}
  //                         />
  //                     </Link>
  //                 )
  //             })}
  //         </GridWrapperAlt>
  //     );
  // }

  return (
    <GridWrapper>
      {items?.map((item, i) => {
        return (
            i === 5 ? <Slot></Slot>
            :
          <div  key={i}>
            <GridItem
              style={{ backgroundImage: `url(${item.background})` }}
              dangerouslySetInnerHTML={{ __html: item.title }}
            />
          </div>
        );
      })}
    </GridWrapper>
  );
};
