import React, { createRef, useEffect, useRef } from 'react';
import { Wrapper } from './styles';
import { Link } from 'gatsby';
import { useLocation } from "@reach/router"

const links = [
  { url: "/photovoltaik", title: "Photovoltaik" },
  { url: "/internet-telefon", title: "Internet / Telefon " },
  { url: "/e-mobilitaet", title: "E-Mobilität" },
  { url: "/elektroinstallationen/", title: "Elektro Installationen" },
]

const SubLinks = () => {
  const location = useLocation();
  const elRefs = useRef(links.map(() => createRef()));

  useEffect(() => {
    const currentLink = elRefs.current.find((item) => item.current.className === 'active')
    currentLink?.current?.scrollIntoView()
  }, [elRefs])

  return (
    <Wrapper >
      {links.map((item, index) => {
        return (
          <Link
          key={index}
            ref={elRefs.current[index]}
            className={location.pathname.includes(item.url) ? "active" : "not-active"}
            to={item.url}>{item.title}
          </Link>
        )})}
    </Wrapper>
  )

};

export default SubLinks;
