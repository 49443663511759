import styled from "styled-components";

export const Wrapper = styled.div`
  a {
    color: #000;
    text-decoration: none;
    font-size: 21px;
	
    font-weight: 100;

	@media (max-width: 1150px) {font-size: 17px;}
	@media (max-width: 1050px) {font-size: 15px;}


	&.active {
		font-weight: bold;
	}
    
	&:hover {
      text-decoration: underline;
    }

    @media (max-width: 1024px) {
		color: #fff;
		font-size: 15px;
	}

	&:last-child {
		display: none;

		@media(max-width: 1024px) {
			display: block;
		}
	}
  }

  ${({ desktop }) =>
    desktop
      ? `
			align-items: center;
			display: flex;
			@media (max-width: 1024px) {
					display: none;
			}


			

			a {
					margin-right: 1rem;
					&:last-child {
							margin-right: unset;
					}
			}
		`
      : `
			padding: 3rem;
			display: flex;
			flex-direction: column;

			a {
					margin-bottom: 1rem;

					&:last-child {
							margin-bottom: unset;
					}
			}
	`}
`;

export const Telephone = styled.div`
a {
    color: #000;
    text-decoration: none;
    font-size: 23px;
    font-weight: 300;

	&.active {
		font-weight: bold;
	}
    
	&:hover {
      text-decoration: underline;
    }

    @media (max-width: 1024px) {
		display: none;
		color: white;
		&:hover {
			color:white;
		}
	}
  }
`