import React from 'react';
import { Link } from 'gatsby';
import { Container } from 'components/common';
import NavbarLinks from '../NavbarLinks';
import { Wrapper, Brand } from './styles';

const Navbar = () => {

  return (
    <Wrapper as={Container}>
      <Brand  as={Link} to="/"
      // style={{fontFamily:'arial', fontSize:'22pt',  color:'black'}}
      >
      <p style={{marginBottom:"4px"}}>
        
        <b> Elektro </b> Huwiler
        </p>
      </Brand>

      
      <NavbarLinks desktop />
    </Wrapper>
  );
};

export default Navbar;
