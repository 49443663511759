import styled, { keyframes } from "styled-components";

export const GridWrapper = styled.div`
  display: grid;
  justify-self: center;
  justify-content: center;
  grid-template-columns: repeat(3, 33%);
  grid-row-gap: 15px;

  grid-column-gap: 20px;

  /* grid-template-rows: 1fr 1fr 1fr; */
  height: 1650px;

  margin-left: 15%;
  margin-right: 15%;
  margin-top: 20px;

  @media (max-width: 1125px) {
    grid-template-columns: repeat(2, 49%);
    height: 2250px;
  }

  @media (max-width: 775px) {
    grid-template-columns: repeat(1, 100%);
    height: 4000px;
  }
  @media (max-width: 395px) {
    grid-template-columns: repeat(1, 100%);
    height: 3350px;
  }
`;

export const Slot = styled.div`
@media (max-width: 1125px) {
display: none;
}

`


export const GridItem = styled.div`
  background-image: url(${(props) => props.img});
  border-radius: 15px;
  width: 100% ;
  height: 400px;
  justify-self: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 395px) {
    height: 65%;
  }

  h2 {
    font-size: 23px;
    padding-top: 410px;
    line-height: 35px;
    text-align: center;
    justify-items: end;
    /* padding-left: 3rem; */
    color: black;
    /* align-self: end; */
    @media (max-width: 395px) {
      padding-top: 280px;
    }

    @media (max-width: 375px) {
      font-size: 20px;
    }

    @media (max-width: 325px) {
      font-size: 18px;
    }

    @media (max-width: 290px) {
      font-size: 16px;
    }
    @media (max-width: 260px) {
      font-size: 15px;
    }
    @media (max-width: 241px) {
      font-size: 14px;
    }
  }

  h3 {
    font-size: 23px;
    padding-top: 410px;
    text-align: center;
    justify-items: end;
    /* padding-left: 3rem; */
    color: black;
    /* align-self: end; */
    @media (max-width: 395px) {
      padding-top: 210px;
    }

    @media (max-width: 375px) {
      font-size: 20px;
    }

    @media (max-width: 325px) {
      font-size: 18px;
    }

    @media (max-width: 290px) {
      font-size: 16px;
    }
    @media (max-width: 260px) {
      font-size: 15px;
    }
    @media (max-width: 241px) {
      font-size: 14px;
    }
  }
`;

export const GridWrapperAlt = styled.div`
  display: grid;
  justify-self: center;
  justify-content: center;
  grid-template-columns: repeat(3, 33%);
  grid-row-gap: 20px;

  grid-column-gap: 30px;

  /* grid-template-rows: 1fr 1fr 1fr; */
  height: 1200px;

  margin-left: 15%;
  margin-right: 15%;
  margin-top: 170px;

  @media (max-width: 1125px) {
    grid-template-columns: repeat(2, 49%);
    height: 1050px;
  }

  @media (max-width: 775px) {
    grid-template-columns: repeat(1, 100%);
    height: 2850px;
  }
  @media (max-width: 395px) {
    grid-template-columns: repeat(1, 100%);
    height: 2250px;
  }
`;

const Overlay = keyframes`
0% {}
100% {box-shadow:inset 0 0 0 2000px rgb(255,255,0, 0.3);}

`;

// export const GridItemAlt= styled.div`
//   background-image: url(${(props) => props.img});
//   border-radius: 15px;
//   width: 100%;
//   height: 80%;
//   justify-self: center;
//   background-size: cover;
//   background-position: center;
//   background-repeat: no-repeat;

//   @media (max-width: 395px) {
//     height: 65%;
//   }

//   h2 {
//     font-size: 23px;
//     padding-top: 320px;
//     text-align: center;
//     justify-items: end;
//     color: black;
//     align-self: end;
//     @media (max-width: 395px) {
//       padding-top: 210px;
//     }

//     @media (max-width: 375px) {
//       font-size: 20px;
//     }

//     @media (max-width: 325px) {
//       font-size: 18px;
//     }

//     @media (max-width: 290px) {
//       font-size: 16px;
//     }
//     @media (max-width: 260px) {
//       font-size: 15px;
//     }
//     @media (max-width: 241px) {
//       font-size: 14px;
//     }
//     &:hover {
//       text-decoration: underline;
//     }
//   }

//   &:hover {
//     animation: ${Overlay} 0.2s ease-in both;
//     /* box-shadow:inset 0 0 0 2000px rgba(255, 0, 150, 0.3);

//      h2{
//          padding-top: 250px;
//          color: white;
//      } */
//   }
// `;
