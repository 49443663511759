import React from "react";
import { Layout, Seo } from "components/common";
import { Intro } from "components/Intro"
import { MainContent } from "components/MainContent"
import { GeneralGrid } from "components/GeneralGrid";

import { aboutContent } from "pagesData/about";
import { IntroRandoAnim } from "components/IntroRandomAnim";

const About = () => (
  <Layout>
    <Seo />
    <IntroRandoAnim  {...aboutContent} />
    <MainContent content={aboutContent.mainContent} />
    <GeneralGrid items={aboutContent.gridItems} />
  </Layout>
);

export default About;
