// about page - about.js

import bg1 from "assets/bilder/uber/BAD_0217_rgb.jpg";
import bg2 from "assets/bilder/uber/AD_9113_rgb.jpg";
import bg3 from "assets/bilder/uber/BAD_0265_rgb_1.jpg";


import marcel from "assets/portraits/Marcel_Imhof_AD_7306_rgb.jpg";
import stef from "assets/portraits/Stefan_Alberici_AD_7356_rgb.jpg";
import martina from "assets/portraits/Martina_Alberici_MBP_BAD_8898.jpeg";
import michael from "assets/portraits/Michael_Kaufmann_AD_7474_rgb.jpg";
import strobel from "assets/portraits/BAP_4052_nils_rgb_final.jpg";
import marc from "assets/portraits/BAP_4058_marc_rgb_final.jpg";
import fabian from "assets/portraits/Fabian_Theiler_MBP_BAD_8937_rgb.jpg";
import pasc from "assets/portraits/Pascal_Tschopp_AD_7439_rgb.jpg";

// import simon from "assets/portraits/Simon_Scheuchzer_AD_7498_rgb.jpg";

export const aboutContent = {
    h2: "Elektro Huwiler GmbH",
    subtitle: "Flexibel, flink, erfahren und zuverlässig",
    // background: aboutBackgroudlatest,
    bgs: [
        bg1,bg2,bg3
      ],
    mainContent: ` <p>
    Wir sind Elektro Huwiler - ein agiles und flexibles Team mit Sitz in Birmensdorf,
     welches auf Kleinaufträge in den Bereichen rund um Strom, Elektrizität, Telefon und Netzwerk spezialisiert ist. 
        <br></br>
        Unsere qualifizierten Mitarbeiter garantieren Ihnen eine korrekte und saubere Ausführung
         der von Ihnen gewünschten Arbeiten. Wir denken nachhaltig und
         handeln als Lehrbetrieb verantwortungsbewusst – so ist sichergestellt, 
         dass der Fortbestand des Know How gewährleistet ist.
        </p>
    `,
    gridItems: [
        { background: marcel, title: "<h2>Marcel Imhof <br/>  Geschäftsleitung <br/> Eidg. dipl. Elektroinstallateur</h2>" },
        { background: stef, title: "<h2>Stefan Alberici <br/> Geschäftsleitung <br/> Eidg. dipl. Elektroinstallateur </h2>" },
        { background: martina, title: "<h2> Martina Alberici <br/> Rechnungswesen / Administration </h2>" },
        { background: michael, title: "<h2> Michael Kaufmann <br/> Elektroinstallateur EFZ</h2>" },
        { background: marc, title: "<h2>Marc Sterchi <br/> Informatiker EFZ <br/> Lernender Montage-Elektriker EFZ</h2>" },
        { background: pasc, title: "" },
        { background: fabian, title: "<h2> Fabian Theiler <br/>Lernender <br/> Elektroinstallateur EFZ </h2>" },
        { background: strobel, title: "<h2>Nils Strobel <br/> Lernender <br/> Elektroinstallateur EFZ </h2>" },

        
    ]
}
