import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 10px 0rem 5px 0px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 75%;
`;

export const Brand = styled.a`
  font-weight: 100;

  font-family: Arial, Helvetica, sans-serif;
  font-size: 24pt;
  color: black;
  margin: 0px;
  font-weight: 100 !important;

  @media (max-width: 420px) {
    font-size: 25px;
  }
`;
