import React, { useState } from 'react';
import { useLocation } from "@reach/router"
import Navbar from './Navbar';
import Hamburger from './Hamburger';
import Sidebar from './Sidebar';
import SubLinks from './SubLinks';
import { Wrapper, Overlay } from './styles';

export const Header = () => {
  const [sidebar, toggle] = useState(false);
  const location = useLocation();

  return (
    <Wrapper>
      <Overlay sidebar={sidebar} onClick={() => toggle(!sidebar)} />
      <Navbar />
      <Hamburger sidebar={sidebar} toggle={toggle} />
      <Sidebar sidebar={sidebar} toggle={toggle} />
      {!['/about', '/', '/kontakt', '/impressum', '/about/', '/kontakt/', '/impressum/'].includes(location.pathname) && (
        <SubLinks />
      )}
    </Wrapper>
  );
};
