import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: #E6CF03;
  z-index: 0;
  width: 100%;
  padding-top: 1px;
  padding-bottom: 15px;


  @media (max-width: 560px) {
    overflow-x: scroll;
    padding-left: 0px;
    justify-content: flex-start;
    

    &::-webkit-scrollbar {
      display: none;
    }
  }
  
  a {
    color: #000;
    padding: 0 10px 0 10px;
    text-decoration: none;
    font-size: 16px;
    font-weight: 200;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    min-width: 140px;
    
    @media (max-width: 520px) {
    min-width: 130px;
    } 
      /* @media (max-width: 440px) {
    min-width: 140px;
    } */
    &.active {
      font-weight: bold;

      @media (max-width: 420px) {
    
    display: none !important;
      }
    }

    &:hover {
      text-decoration: underline;
    }


    @media (max-width: 1040px) {
      font-size: 16px;
      padding: -10px 5px -10px 5px;
    }


    @media (max-width: 900px) {
      font-size: 14px;
    }
    &:last-child {
      margin-bottom: unset;
    }
  }
`;

