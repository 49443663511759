import React, { useEffect, useState } from 'react';
import { Header } from 'components/Header';
import { Container } from 'components/common';
import { Wrapper, IntroWrapper, H2, Content, Subtitle } from './styles';

export const IntroRandoAnim = ({
    h2,
    subtitle,
    bgs
}) => {


      // console.log(bgs.length, "bgs")
    const [images, setImage] = useState(1)
    
    const len = bgs.length
    useEffect(() => {
      let number = Math.floor(Math.random() * len)
      // console.log(number, "nr")
      setImage(number);
   }, []);




  return (
    <Wrapper background={bgs[images]}>
      
      <Header />
     
      <IntroWrapper as={Container}
        style={{textAlign: 'center',
      }}>
        <Content>
          <H2 dangerouslySetInnerHTML={{ __html: h2 }} />
          <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
        </Content>
      </IntroWrapper>
    </Wrapper>
  );
};
