import React from 'react';
import { Container } from 'components/common';
import { Wrapper, SkillsWrapper, Details } from './styles';

export const MainContent = ({ content }) => {

  return (
    <Wrapper id="about">
      <SkillsWrapper as={Container}>
        <Details dangerouslySetInnerHTML={{ __html: content }} />
      </SkillsWrapper>
    </Wrapper>
  );
};
