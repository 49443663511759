import React from 'react';
import { useLocation } from "@reach/router"
import { Link } from 'gatsby';

import { Wrapper, Telephone } from './styles';

const links = [
  { url: "/photovoltaik", url2:"/internet-telefon",url3:"/elektroinstallationen",url4:"/e-mobilitaet", title: "Angebot" },
  { url: "/about", title: "Über uns" },
  { url: "/kontakt", title: "Kontakt" },
]

const NavbarLinks = ({ desktop }) => {
  const location = useLocation();

  return (
    <>
      <Wrapper desktop={desktop}>
        <Link
          className={location.pathname === "/" ? "active" : "not-active"}
          to="/">Home
        </Link>
        {links.map((item, index) => {
          return (
            <Link
            key={index}
              className={location.pathname.includes(item.url) ||
                location.pathname.includes(item.url2) ||
                location.pathname.includes(item.url3) ||
                location.pathname.includes(item.url4)
                ? "active" : "not-active"}
              to={item.url}>{item.title}

            </Link>
        )})}
      
        <a style={{ fontSize: '1.2rem' }} activeclassname="active" href="tel:0041798006544">Notfalltelefon: 079 800 65 44</a>
      </Wrapper>
      <Telephone>
        <a style={{ fontSize: '1.3rem' }} activeclassname="active" href="tel:0041798006544">Notfalltelefon: 079 800 65 44</a>
      </Telephone>
    </>
  )

};

export default NavbarLinks;
